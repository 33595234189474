import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import generateStore from "./createRedux/store";

const Provincias = lazy(() => import("./components/provincias"));
const ProvinciasColombia = lazy(() => import("./components/provinciasColombia"));
const ProvinciasCongreso = lazy(() =>
  import("./components/provinciasCongreso")
);
const Porcentajes = lazy(() => import("./components/porcentajes"));
const CustomText = lazy(() => import("./components/customText"));
const Tabla = lazy(() => import("./components/Tabla"))
const TablaCongreso = lazy(() => import("./components/TablaCongreso"))
const ProvinciasColSegundaVuelta = lazy(() => import("./components/provinciasColSegundaVuelta"));

const App = () => {
  const store = generateStore();

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Suspense fallback="">
            <Route path="/provincias" exact>
              <Provincias />
            </Route>
            <Route path="/congreso" exact>
              <ProvinciasCongreso />
            </Route>
            <Route path="/porcentajes" exact>
              <Porcentajes />
            </Route>
            <Route path="/porcentajes-texto" exact>
              <CustomText />
            </Route>
            <Route path="/tabla" exact>
              <Tabla />
            </Route>
            <Route path="/tabla-congreso" exact>
              <TablaCongreso />
            </Route>
            <Route path="/provincias/colombia" exact>
              <ProvinciasColombia />
            </Route>
            <Route path="/provincias/colombia-segunda-vuelta" exact>
              <ProvinciasColSegundaVuelta />
            </Route>
          </Suspense>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
