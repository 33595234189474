import axios from "axios";

// Constants
const provincias = [
  { percentage: "", party: "", code: "AR-B", name: "Buenos Aires" },
  {
    percentage: "",
    party: "",
    code: "AR-C",
    name: "Ciudad Autónoma de Buenos Aires",
  },
  { percentage: "", party: "", code: "AR-S", name: "Santa Fe" },
  { percentage: "", party: "", code: "AR-X", name: "Córdoba" },
  { percentage: "", party: "", code: "AR-M", name: "Mendoza" },
  { percentage: "", party: "", code: "AR-E", name: "Entre Ríos" },
  { percentage: "", party: "", code: "AR-K", name: "Catamarca" },
  { percentage: "", party: "", code: "AR-W", name: "Corrientes" },
  { percentage: "", party: "", code: "AR-H", name: "Chaco" },
  { percentage: "", party: "", code: "AR-U", name: "Chubut" },
  { percentage: "", party: "", code: "AR-P", name: "Formosa" },
  { percentage: "", party: "", code: "AR-Y", name: "Jujuy" },
  { percentage: "", party: "", code: "AR-L", name: "La Pampa" },
  { percentage: "", party: "", code: "AR-F", name: "La Rioja" },
  { percentage: "", party: "", code: "AR-N", name: "Misiones" },
  { percentage: "", party: "", code: "AR-Q", name: "Neuquén" },
  { percentage: "", party: "", code: "AR-R", name: "Río Negro" },
  { percentage: "", party: "", code: "AR-A", name: "Salta" },
  { percentage: "", party: "", code: "AR-J", name: "San Juan" },
  { percentage: "", party: "", code: "AR-D", name: "San Luis" },
  { percentage: "", party: "", code: "AR-Z", name: "Santa Cruz" },
  { percentage: "", party: "", code: "AR-G", name: "Santiago del Estero" },
  { percentage: "", party: "", code: "AR-V", name: "Tierra del Fuego" },
  { percentage: "", party: "", code: "AR-T", name: "Tucumán" },
];

const initialState = {
  provincias,
  provinciasPaso: provincias,
  frenteVsJuntos: provincias.map(({ code, name }) => ({
    code,
    province: name,
    parties: [],
  })),
  hasData: false,
};

// Types
const PROVINCIAS_GENERAL = "PROVINCIAS_GENERAL";
const PROVINCIAS_PASO = "PROVINCIAS_PASO";
const HAS_DATA = "HAS_DATA";
const FRENTE_VS_JUNTOS = "FRENTE_VS_JUNTOS";

// Reducers
export default function provinciasReducer(state = initialState, action) {
  switch (action.type) {
    case PROVINCIAS_GENERAL:
      return { ...state, provincias: action.payload };

    case PROVINCIAS_PASO:
      return { ...state, provinciasPaso: action.payload };

    case FRENTE_VS_JUNTOS:
      return { ...state, frenteVsJuntos: action.payload, hasData: true };

    case HAS_DATA:
      return {
        ...state,
        hasData: action.payload,
        frenteVsJuntos: state.frenteVsJuntos.map(
          ({ code, province, parties }) => ({
            code,
            province: province,
            parties: parties.map((x) => ({
              ...x,
              percentage: { general: 0, paso: 0 },
            })),
          })
        ),
      };

    default:
      return state;
  }
}

const functionFindProvences = (data, province) => {
  const dataFind = data.find((_province) => _province.code === province.code);

	dataFind.parties.sort((a, b) => (Number(a.votes_percent) < Number(b.votes_percent) ? 1 : -1))

  if (dataFind !== undefined) {
    return {
      percentage: dataFind.parties[0].votes_percent,
      party: dataFind.parties[0].name,
      name: dataFind.name,
      color: dataFind.parties[0].settings.color,
      code: dataFind.code,
    };
  } else {
    return {
      percentage: "",
      party: "",
      name: province.name,
      code: province.code,
    };
  }
};

const compareGeneralPaso = (general, paso) => {
  return general.map((item) => {
    const currentProvince = paso.find(
      (province) => province.code === item.code
    );

    const currentParty = currentProvince?.parties.find(
      (party) => party.name === item.party
    );

    return {
      percentage: currentParty?.votes_percent ?? 0,
      party: currentParty?.name ?? "",
      name: currentProvince?.name ?? item.name,
      color: currentParty?.settings.color ?? "",
      code: currentProvince?.code ?? item.code,
    };
  });
};

const getDataPaso = async (electionType, delegatesList) => {
  const URL =
    electionType === "delegados"
      ? "https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_delegates.json"
      : "https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_senators.json";
  const resPaso = await axios.get(`${URL}?timestamp=${new Date().getTime()}`);
  const payloadPaso = resPaso.data.districts;

  return compareGeneralPaso(delegatesList, payloadPaso);
};

const getURL = (source, electionType, currentElection) => {
  switch (source) {
    case "aws":
      if (electionType === "delegados") {
        if (currentElection === "paso") {
          return `https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_delegates.json`;
        }

        return `https://data-ecp.infobae.com/infobae.com/2021/argentina/general/AR_delegates.json`;
      } else {
        if (currentElection === "paso") {
          return `https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_senators.json`;
        }

        return `https://data-ecp.infobae.com/infobae.com/2021/argentina/general/AR_senators.json`;
      }

    case "infobae":
      if (electionType === "delegados") {
        return `https://infobae-elections-manual-data.s3.amazonaws.com/province_delegates_generated.json`;
      } else {
        return `https://infobae-elections-manual-data.s3.amazonaws.com/province_senators_generated.json`;
      }

    default:
      if (electionType === "delegados") {
        return `https://infobae-elections-manual-data.s3.amazonaws.com/map_delegates.json`;
      } else {
        return `https://infobae-elections-manual-data.s3.amazonaws.com/map_senators.json`;
      }
  }
};

const getListData = (source, response) => {
  switch (source) {
    case "aws":
      return provincias.map((province) =>
        functionFindProvences(response.data.districts, province)
      );

    case "infobae":
      return provincias.map((x) => {
        const province = response.data.provinces.find((y) => x.code === y.code);

        return { ...x, ...(province ? province : {}) };
      });

    default:
      return JSON.parse(response.data.data);
  }
};

export const getProvinciasAction = ({
  electionType,
  source,
  showTooltip,
  currentElection,
}) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${getURL(
          source,
          electionType,
          currentElection
        )}?timestamp=${new Date().getTime()}`
      );
      const listData = getListData(source, res);

      dispatch({
        type: PROVINCIAS_GENERAL,
        payload: listData,
      });

      if (showTooltip) {
        const listPaso = await getDataPaso(electionType, listData);

        dispatch({
          type: PROVINCIAS_PASO,
          payload: listPaso,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFrenteVSJuntosAction = ({
  electionType,
  source,
  currentElection,
}) => {
  const frenteDeTodos = [
    "frente de todos",
    "fuerza san luis",
    "frente cívico por santiago",
  ];
  const juntosPorElCambio = [
    "juntos por el cambio",
    "frente cambia mendoza",
    "juntos por entre rios",
    "eco + vamos corrientes",
    "chaco cambia + juntos por el cambio",
    "cambia jujuy",
    "cambia neuquén",
    "cambia santa cruz",
    "juntos por el cambio tierra del fuego",
    "unidos por san luis",
    "cambia mendoza",
    "juntos por el cambio chubut",
    "juntos somos río negro",
    "vamos la rioja"
  ];

  const functionFindProvencesForTable = (data, province) => {
    const dataFind = data.find((_province) => _province.code === province.code);

    if (dataFind !== undefined) {
      const parties = [];

      const indexFrente = dataFind.parties.findIndex((x) =>
        frenteDeTodos.includes(x.name.toLowerCase())
      );
      if (indexFrente >= 0) {
        const party = dataFind.parties[indexFrente];
        const frente = {
          percentage: {
            general: party.votes_percent.toFixed(2),
            paso: 0,
          },
          color: "#0066C1",
          name: party.name,
          code: "frente",
        };

        parties.push(frente);
      }

      const indexJuntos = dataFind.parties.findIndex((x) =>
        juntosPorElCambio.includes(x.name.toLowerCase())
      );
      if (indexJuntos >= 0) {
        const party = dataFind.parties[indexJuntos];
        const juntos = {
          percentage: {
            general: party.votes_percent.toFixed(2),
            paso: 0,
          },
          color: "#f8ac29",
          name: party.name,
          code: "juntos",
        };

        parties.push(juntos);
      }

      const provinceData = {
        province:
          dataFind.code === "AR-C"
            ? "CABA"
            : dataFind.code === "AR-B"
            ? "PBA"
            : dataFind.name,
        code: dataFind.code,
        parties,
      };

      return provinceData;
    } else {
      return {
        parties: [
          {
            name: "Juntos por el cambio",
            color: "#f8ac29",
            code: "juntos",
            percentage: {
              general: 0,
              paso: 0,
            },
          },
          {
            name: "Frente de todos",
            color: "#0066C1",
            code: "frente",
            percentage: {
              general: 0,
              paso: 0,
            },
          },
        ],
        province:
          province.code === "AR-C"
            ? "CABA"
            : province.code === "AR-B"
            ? "PBA"
            : province.name,
        code: province.code,
      };
    }
  };

  const getGeneralData = (source, response) => {
    switch (source) {
      case "aws":
        return provincias.map((province) =>
          functionFindProvencesForTable(response.data.districts, province)
        );

      case "infobae":
        return provincias.map((x) => {
          const province = response.data.provinces.find(
            (y) => x.code === y.code
          );

          return { ...x, ...(province ? province : {}) };
        });

      default:
        return JSON.parse(response.data.data);
    }
  };

  const compareGeneralPasoForTable = (general, paso) => {
    return general.map((item) => {
      const currentProvincePaso = paso.find(
        (province) => province.code === item.code
      );

      if (currentProvincePaso) {
        const indexFrente = currentProvincePaso.parties.findIndex((x) =>
          frenteDeTodos.includes(x.name.toLowerCase())
        );
        if (indexFrente >= 0) {
          const currentPartyIndexGeneral = item.parties.findIndex(
            (x) => x.code === "frente"
          );
          if (item.parties[currentPartyIndexGeneral]) {
            item.parties[currentPartyIndexGeneral].percentage.paso =
              currentProvincePaso.parties[indexFrente].votes_percent.toFixed(2);
          } else {
            item.parties[currentPartyIndexGeneral] = {
              percentage: { paso: 0 },
            };
          }
        }

        const indexJuntos = currentProvincePaso.parties.findIndex((x) =>
          juntosPorElCambio.includes(x.name.toLowerCase())
        );

        if (indexJuntos >= 0) {
          const currentPartyIndexGeneral = item.parties.findIndex(
            (x) => x.code === "juntos"
          );

          if (item.parties[currentPartyIndexGeneral]) {
            item.parties[currentPartyIndexGeneral].percentage.paso =
              currentProvincePaso.parties[indexJuntos].votes_percent.toFixed(2);
          } else {
            item.parties[currentPartyIndexGeneral] = {
              percentage: { paso: 0 },
            };
          }
        }

        item.parties.sort((a, b) =>
          a.percentage.paso < b.percentage.paso ? 1 : -1
        );

        return item;
      }

      item.parties.sort((a, b) => (a.name > b.name ? 1 : -1));

      return item;
    });
  };

  const getPasoData = async (electionType, delegatesList) => {
    const URL =
      electionType === "delegados"
        ? "https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_delegates.json"
        : "https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_senators.json";
    const resPaso = await axios.get(`${URL}?timestamp=${new Date().getTime()}`);
    const payloadPaso = resPaso.data.districts;

    return compareGeneralPasoForTable(delegatesList, payloadPaso);
  };

  return async (dispatch) => {
    dispatch({
      type: HAS_DATA,
      payload: false,
    });

    try {
      const res = await axios.get(
        `${getURL(
          source,
          electionType,
          currentElection
        )}?timestamp=${new Date().getTime()}`
      );

      console.log('res', res)

      const listData = getGeneralData(source, res);
      const listPaso = await getPasoData(electionType, [...listData]);

      dispatch({
        type: FRENTE_VS_JUNTOS,
        payload: listPaso,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
