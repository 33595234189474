import axios from "axios";

// Types
const PORCENTAJES = "PORCENTAJES";
const HAS_DATA = "HAS_DATA";

// Reducers
export default function porcentajesReducer(
  state = { listPercentsVotes: [], totalPercent: null, text: null, hasData: false },
  action
) {
  switch (action.type) {
    case PORCENTAJES:
      return { ...state, ...action.payload };
    case HAS_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

const sortParties = (parties) =>
  parties.sort((a, b) => (Number(a.percent) < Number(b.percent) ? 1 : -1));

export const getPorcentajesAction = (source, currentElection, data) => async (dispatch) => {
  try {
    if (source === "aws") {
      if (data === "delegados") {
        if (currentElection === "paso") {
          const resDelegates = await axios.get("https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_delegates.json");

          const payloadDelegates = resDelegates.data.blocks;

          const parties = payloadDelegates.map((x) => ({
            name: x.name,
            percent: x.votes_percent,
            color: x.color,
          }));

          const totalPercent = Math.floor(resDelegates.data.tables_percent);
          dispatch({
            type: PORCENTAJES,
            payload: {
              listPercentsVotes: parties,
              totalPercent,
            },
          });
        } else {
          const resDelegates = await axios.get("https://data-ecp.infobae.com/infobae.com/2021/argentina/general/AR_delegates.json");

          const payloadDelegates = resDelegates.data.blocks;

          const parties = payloadDelegates.map((x) => ({
            name: x.name,
            percent: x.votes_percent,
            color: x.color,
          }));

          const totalPercent = Math.floor(resDelegates.data.tables_percent);
          dispatch({
            type: PORCENTAJES,
            payload: {
              listPercentsVotes: parties,
              totalPercent,
            },
          });
        }
      } else if (data === "senadores") {
        if (currentElection === "paso") {
          const resDelegates = await axios.get("https://data-ecp.infobae.com/infobae.com/2021/argentina/paso/AR_senators.json");

          const payloadDelegates = resDelegates.data.blocks;

          const parties = payloadDelegates.map((x) => ({
            name: x.name,
            percent: x.votes_percent,
            color: x.color,
          }));

          const totalPercent = Math.floor(resDelegates.data.tables_percent);
          dispatch({
            type: PORCENTAJES,
            payload: {
              listPercentsVotes: parties,
              totalPercent,
            },
          });
        } else {
          const resDelegates = await axios.get("https://data-ecp.infobae.com/infobae.com/2021/argentina/general/AR_senators.json");

          const payloadDelegates = resDelegates.data.blocks;

          const parties = payloadDelegates.map((x) => ({
            name: x.name,
            percent: x.votes_percent,
            color: x.color,
          }));

          const totalPercent = Math.floor(resDelegates.data.tables_percent);
          dispatch({
            type: PORCENTAJES,
            payload: {
              listPercentsVotes: parties,
              totalPercent,
            },
          });
        }
      }
    } else {
      if (data === "delegados") {
        const res = await axios.get(
          `https://infobae-elections-manual-data.s3.amazonaws.com/partie_delegates.json?timestamp=${new Date().getTime()}`
        );

        const resJson = JSON.parse(res.data.data);

        const parties = resJson.parties.map((x) => ({
          name: x.party,
          percent: x.percentage,
          color: x.color,
        }));

        dispatch({
          type: PORCENTAJES,
          payload: {
            listPercentsVotes: sortParties(parties),
            totalPercent: resJson.tables,
            text: resJson.text,
          },
        });
      } else if (data === "senadores") {
        const res = await axios.get(
          `https://infobae-elections-manual-data.s3.amazonaws.com/partie_senators.json?timestamp=${new Date().getTime()}`
        );

        const resJson = JSON.parse(res.data.data);

        const parties = resJson.parties.map((x) => ({
          name: x.party,
          percent: x.percentage,
          color: x.color,
        }));

        dispatch({
          type: PORCENTAJES,
          payload: {
            listPercentsVotes: sortParties(parties),
            totalPercent: resJson.tables,
            text: resJson.text,
          },
        });
      } else {
        const res = await axios.get(
          `https://infobae-elections-manual-data.s3.amazonaws.com/colors.json?timestamp=${new Date().getTime()}`
        );

        const resJson = JSON.parse(res.data.data);

        const parties = resJson.parties.map((x) => ({
          name: x.party,
          percent: x.percentage,
          color: x.color,
        }));

        dispatch({
          type: PORCENTAJES,
          payload: {
            listPercentsVotes: sortParties(parties),
            totalPercent: resJson.tables,
            text: resJson.text,
          },
        });
      }
    }

    dispatch({
      type: HAS_DATA,
      payload: { hasData: true },
    });
  } catch (error) {
    console.log(error);
  }
};
