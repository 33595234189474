import {
	createStore,
	combineReducers,
	compose,
	applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import porcentajesReducer from './porcentajes';
import provinciasReducer from "./provinciasDuck";

const rootReducer = combineReducers({
	provinciasData: provinciasReducer,
	porcentajesData: porcentajesReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk) ));

	return store
};
